if (!String.format) {
   String.format = function() {
      console.warn('String.format is an unsafe extension to String. Please use Alkami.Utils.StringHelper.format instead.');
      return Alkami.Utils.StringHelper.format.apply(null, arguments);
   }
}

if (!window.hasOwnProperty('POBoxRegexPattern')) {
    Object.defineProperty(window, 'POBoxRegexPattern', {
        get() {
            console.warn('POBoxRegexPattern is deprecated. Please use Alkami.Patterns.POBox instead.');
            return Alkami.Patterns.POBox;
        }
    });
}

class HashTable {
   public length = 0;
   public items: { [key: string]: any } = {};

   constructor(obj: any) {
      console.warn('HashTable is deprecated. Please use Javascript Object API instead.');
      for (const p in obj) {
         if (obj.hasOwnProperty(p)) {
            this.items[p] = obj[p];
            this.length++;
         }
      }
   }

   public clear(): void {
      this.items = {};
      this.length = 0;
   }

   public each(fn: (item: any, key: string) => void): void {
      Object.keys(this.items).forEach((k) => fn(k, this.items[k]));
   }

   public hasItem(key: any): any {
      return this.items.hasOwnProperty(key);
   }

   public keys(): string[] {
      return Object.keys(this.items);
   }

   public values(): any[] {
      return Object.keys(this.items).map((key) => this.items[key]);
   }

   public setItem(key: any, value: any): any {
      let previous: any;
      if (this.hasItem(key)) {
         previous = this.items[key];
      } else {
         this.length++;
      }
      this.items[key] = value;
      return previous;
   }

   public removeItem(key: any): any {
      if (this.hasItem(key)) {
         const previous: any = this.items[key];
         this.length--;
         delete this.items[key];
         return previous;
      }

      return undefined;
   }
}